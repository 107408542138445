<template>
	<div class="page">
		<el-row>
			<!--Table-->
			<el-table
					v-if="mailchimp.length"
					:data="mailchimp"
					ref="table"
					v-loading="gridLoad"
					border
					stripe>
				<el-table-column
						align="left"
						prop="kampanja"
						:label="$t('mailchimp.kampanja')">
				</el-table-column>
				<el-table-column
						align="left"
						prop="naslov"
						:label="$t('mailchimp.naslov')">
				</el-table-column>
				<el-table-column
						align="center"
						prop="mail_poslato"
						width="120"
						:label="$t('mailchimp.poslato')">
					<template slot-scope="scope">
						{{$utils('formatBroja', scope.row.mail_poslato || null, 0)}}
					</template>
				</el-table-column>
				<el-table-column
						align="center"
						prop="mail_odjavljeno"
						width="120"
						:label="$t('mailchimp.odjavljeno')">
					<template slot-scope="scope">
						{{$utils('formatBroja', scope.row.mail_odjavljeno || null, 0)}}
					</template>
				</el-table-column>
				<el-table-column
						align="center"
						prop="mail_vreme_slanja"
						width="200"
						:label="$t('mailchimp.vremeSlanja')">
					<template slot-scope="scope">
						{{$utils('formatDate', scope.row.mail_vreme_slanja, 'HH:mm [/] DD.MM.YYYY')}}
					</template>
				</el-table-column>
				<el-table-column
						align="center"
						prop="mail_neisporuceno"
						width="120"
						:label="$t('mailchimp.neisporuceno')">
					<template slot-scope="scope">
						{{$utils('formatBroja', scope.row.mail_neisporuceno || null, 0)}}
					</template>
				</el-table-column>
				<el-table-column
						align="center"
						prop="mail_otvaranja"
						width="120"
						:label="$t('mailchimp.otvaranja')">
					<template slot-scope="scope">
						{{$utils('formatBroja', scope.row.mail_otvaranja || null, 0)}}
					</template>
				</el-table-column>
				<el-table-column
						align="center"
						prop="mail_otvaranja_procenat"
						width="180"
						:label="$t('mailchimp.otvaranjaProcenat')">
					<template slot-scope="scope">
						{{$utils('formatBroja', scope.row.mail_otvaranja_procenat || null)}} %
					</template>
				</el-table-column>
				<el-table-column
						align="center"
						prop="mail_klik_ukupno"
						width="150"
						:label="$t('mailchimp.klikUkupno')">
					<template slot-scope="scope">
						{{$utils('formatBroja', scope.row.mail_klik_ukupno || null, 0)}}
					</template>
				</el-table-column>
				<el-table-column
						align="center"
						prop="mail_klik_unikat"
						width="150"
						:label="$t('mailchimp.klikUnikat')">
					<template slot-scope="scope">
						{{$utils('formatBroja', scope.row.mail_klik_unikat || null, 0)}}
					</template>
				</el-table-column>
				<el-table-column
						align="center"
						prop="mail_klik_procenat"
						width="150"
						:label="$t('mailchimp.klikProcenat')">
					<template slot-scope="scope">
						{{$utils('formatBroja', scope.row.mail_klik_procenat || null)}} %
					</template>
				</el-table-column>
			</el-table>
			<el-alert v-else :title="$t('global.nemaPodataka')" type="warning" :closable="false"/>
		</el-row>
	</div>
</template>

<script>
export default {
	name: 'mailchimp-list',
	data() {
		return {
			mailchimp: [],
			gridLoad: false
		};
	},
	mounted() {
		this.$store.dispatch('setHeader', {
			title: this.$t('mailchimp.mailchimp')
		});

		this.$utils('autoLoad');
		this.$utils('stopLoadingAfter', [this.getMailchimp()]);
	},
	methods: {
		getMailchimp() {
			this.gridLoad = true;
			return this.$get('mailchimp').then(data => {
				this.mailchimp = data;
				this.gridLoad = false;
			});
		}
	}
};
</script>
